import React, { createRef } from 'react';
import Benefits from '../components/Benefits'
import Header from '../components/Header'
import HomeHero from '../components/HomeHero'
import Ourpartners from '../components/Ourpartners'
import Features from '../components/Features'
import Footer from '../components/Footer'

function Landing() {
  const contactRef = createRef()
  return (
    <>
      <Header contactRef={contactRef}></Header>
      <HomeHero />
      <Ourpartners />
      <Benefits />
      <Features />
      <div ref={contactRef} >
        <Footer />
      </div>
    </>
  )
}

export default Landing