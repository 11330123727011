import React from 'react'
import ImageSliders from './ImageSliders'
import Logos from './Logos'
import Whoarewe from './Whoarewe'

function Ourpartners() {
  return (
    <section className="section_light section_curved">
        <div className="container">
            <div className="logos_section">
                <div className="section_title">
                    Our Partners
                </div>

                <Logos logos={[
                  "mercy_corps",
                  "ciat",
                  "cropnuts",
                  "planet",
                  "ocp",
                  "idh"
                ]}/>

                <Whoarewe />

            </div>
        </div>
        <ImageSliders />
    </section>
  )
}

export default Ourpartners