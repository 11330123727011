import React from 'react'

function Showcase(props) {
  return (
    <div className="showcase row">
      {typeof props.pages !== 'undefined' && typeof props.activePage !== 'undefined' &&
        <>
          {typeof props.pages[props.activePage] !== 'undefined' &&
            <>
              <div className={`showcase_image col-sm-5 
            ${props.pages[props.activePage].imageClass}`}>
              </div>
              <div className="showcase_details col-sm-7">
                {typeof props.pages[props.activePage].icon !== "undefined"
                  && props.pages[props.activePage].icon !== ""
                  && props.pages[props.activePage].icon !== "hide" &&
                  <div className={`icon ${props.pages[props.activePage].icon}`}></div>
                }
                <div className="title">{props.pages[props.activePage].title}</div>
                <div className="text">
                  {props.pages[props.activePage].text}
                </div>

                <div className='buttons'>
                  <div className={`btn ${props.pages[props.activePage].btnClass}`}
                    onClick={typeof props.pages[props.activePage].btnAction !== 'undefined' ? props.pages[props.activePage].btnAction : () => {
                      console.log('Button clicked');
                    }}
                  >{props.pages[props.activePage].btnText}</div>
                  {typeof props.pages[props.activePage].btn2Action !== 'undefined' &&
                    <div className={`btn ${props.pages[props.activePage].btn2Class}`}
                      onClick={typeof props.pages[props.activePage].btn2Action !== 'undefined'
                        ? props.pages[props.activePage].btn2Action
                        : () => {
                          console.log('Button clicked');
                        }}
                    >{props.pages[props.activePage].btn2Text}</div>
                  }
                </div>
              </div>
            </>
          }
        </>
      }
    </div >
  )
}

export default Showcase