import React from 'react'

function HeroInner(props) {
  return (
    <section className={`hero ${typeof props.herotype !== "undefined" ? props.herotype : ""}`}>
      <div className="container">
        <div className="hero_title">
            {props.title}
        </div>
        <div className="hero_subtitle">
            {props.subtitle}
        </div>
        </div>
    </section>
  )
}

export default HeroInner