import React from 'react'


function Logos(props) {
  return (
    <div className="logos">
      {typeof props.logos !== 'undefined' &&
      <>
      {props.logos.map((logo, index)=>{
        return(
          <div className={`logo ${logo}`} key={index}></div>
        )
      })}
      </>
      }
    </div>
  )
}

export default Logos