import React, { useContext, createRef} from 'react'
import { useParams } from 'react-router-dom'
import {mainFunctions} from "../providers/MainProvider";
import Header from '../components/Header'
import CareerDetails from '../components/CareerDetails'
import Footer from '../components/Footer'
import HeroInner from '../components/HeroInner'

export default function CareerDetailsPage() {
  const contactRef = createRef()
  const {
    openings
  } = useContext(mainFunctions)
  const {id} = useParams()
  return (
    <>
      <Header contactRef={contactRef}></Header>
      <HeroInner 
      title={openings[id].title}
      subtitle={openings[id].description}
      herotype="hero3"
      />
      <CareerDetails />
      <div ref={contactRef} >
        <Footer />
      </div>
    </>
  )
}
