import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Showcase from './Showcase'
import TabButtons from './TabButtons'

export default function Benefits() {
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState(0)

  const tabPages = {
    activePage,
    setActivePage,
    pages: [
      {
        id: 0,
        icon: 'bag_tick',
        imageClass: "foodsecurity",
        title: "Food Security",
        text: "Maximizing crop yield output is important as projections suggest the world’s vegetable and legume (grains) yields will decrease by 35% in 2100. Digitizing agricultural operations is also very important as 35% –50% of the food produced is wasted, resulting in a 25% loss of freshwater.",
        btnClass: 'btn_solid',
        btnText: 'Join Processor Network',
        btnAction: () => {
          navigate("/processor")
        },
        btn2Class: 'btn-light',
        btn2Text: 'Join Merchant Network',
        btn2Action: () => {
          navigate("/merchant")
        }
      },
      {
        id: 1,
        icon: 'cloud',
        imageClass: "climatechange",
        title: "Climate Change",
        text: "With IziMonie, we are tracking the health and output of the soil, thereby facilitating more vegetation and afforestation which promotes a more carbon-free society and reduce global GHG emissions",
        btnClass: 'btn_solid',
        btnText: 'Join Processor Network',
        btnAction: () => {
          navigate("/processor")
        },
        tn2Class: 'btn-light',
        btn2Text: 'Join Merchant Network',
        btn2Action: () => {
          navigate("/merchant")
        }
      },
      {
        id: 2,
        icon: 'trees',
        imageClass: "biodiversity",
        title: "Biodiversity",
        text: "Agriculture-related output is to blame for 80% of worldwide deforestation. The safety of food and the health of the soil are being impacted by excessive fertilizer and chemical use. Our A.I. solution will incorporate biodiversity monitoring, analysis, and improvements.",
        btnClass: 'btn_solid',
        btnText: 'Join Processor Network',
        btnAction: () => {
          navigate("/processor")
        },
        btn2Class: 'btn-light',
        btn2Text: 'Join Merchant Network',
        btn2Action: () => {
          navigate("/merchant")
        }
      }
    ]
  }

  return (

    <section className="section_dark section_curved">
      <div className="container">
        <div className="section_title">Benefits</div>
        <div className="section_subtitle">Why IziMonie?</div>
        <div className="section_text">
          The benefits of Artificial Intelligence and Analytics applied to Finance for Agriculture goes beyond generating affordable AgriFin services but also benefitting the environment and Ag-ecosystem
        </div>

        <TabButtons
          {...tabPages}
        />

        <Showcase
          {...tabPages}
        />



      </div>
    </section>
  )
}
