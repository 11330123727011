import React, {useEffect, useState, useContext} from 'react'
import { Link } from 'react-router-dom';
import {mainFunctions} from "../providers/MainProvider";

export default function CareerListing() {
    const {
        openings
    } = useContext(mainFunctions)

    const [filterText, setFilterText] = useState("")
    const [filteredOpenings, setFilteredOpenings] = useState(openings)
    useEffect(()=>{
        console.log(openings)
        let toreturn = false
        
        let f = openings.filter((opening, index)=>{
            if(filterText !== ""){
            let ot = opening.title.toLowerCase()
            let ft = filterText.toLowerCase()
            ot.includes(ft) ? 
                toreturn = true 
                : toreturn = false;
            }else{
                return true
            }
            return toreturn

        })
        setFilteredOpenings(f)
       
    }, [filterText])
  return (
    <section className="section_light career_openings">
                <div className="container">
                    <div className="career_top">
                        <div className="title">Current Openings</div>
                        <div className="filters">
                            <div className="fieldset">
                                <select defaultValue="null">
                                    <option disabled value="null" >All Departments</option>
                                    <option>Software Engineering</option>
                                    <option>Project Managers</option>
                                </select>
                            </div>
                            <div className="fieldset">
                                <input placeholder="Search" onChange={(e)=>setFilterText(e.target.value)}/>
                                <div className="icon search"></div>
                            </div>
                        </div>
                    </div>
                    <div className="career_body">
                        {filteredOpenings.map((opening, index)=>{
                            return (
                                <Link to={`/careers/${index}`} className="job_item" key={index}>
                                    
                                    <div className="job_item_body">
                                        <div className="title">{opening.title}</div>
                                        <div className="text">
                                            {opening.description}
                                        </div>
                                    </div>
                                    <div className="icon next"></div>
                                </Link>
                            )
                        })}
                        
                        
                    </div>
                </div>
            </section>
  )
}
