import React from 'react'
import { useNavigate } from 'react-router-dom'

function HomeHero() {
    const navigate = useNavigate()


  return (
    <section className="hero">
        <div className="hero_title">
            Data Driven Lending for Agriculture
        </div>
        <div className="hero_subtitle">
         Offering Buy-Now-Pay-At-Harvest loans for farmers, and Agro-Data Intelligence
        </div>
        <div className="hero_buttons">
            
            <div className="btn btn_solid" onClick={()=>navigate("/merchant")}>  
                Join Merchant Network
            </div>
            <div className="btn btn-light" onClick={()=>navigate("/processor")}>
                Join Processor Network
            </div>
        </div>
    </section>
  )
}

export default HomeHero