import React, { createRef, useState, useContext } from 'react';
import { mainFunctions } from "../providers/MainProvider";
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import Header from '../components/Header'
// import CareerListing from '../components/CareerListing'
import HeroInner from '../components/HeroInner'
import Footer from '../components/Footer'

export default function ProcessorNetwork() {
  const contactRef = createRef()
  const {
    validText,
    validPhone,
    validEmail
  } = useContext(mainFunctions)
  const [formDetails, setformDetails] = useState({
    title: 'join_processor_network',
    source: 'izimonie'
  })

  const updateformDetails = (name, value) => {
    setformDetails({ ...formDetails, [name]: value })
  }

  const allValid = () => {

    return validEmail(formDetails.email) &&
      validPhone(formDetails.phoneNumber) &&
      validText(formDetails.firstname) &&
      validText(formDetails.lastname) &&
      validText(formDetails.businessName) &&
      validText(formDetails.businessAddress)
  }

  const submitForm = () => {
    if (allValid()) {
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formDetails),
        redirect: 'follow'
      };

      fetch("https://pqa45jgunk.execute-api.us-east-2.amazonaws.com/default/FormEmailSenderV2", requestOptions)
        .then(response => response.json())
        .then(result => {
          toast.success('Request to join processor network submitted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setformDetails({
            title: "Join processor network"
          })
        })
        .catch(error => console.log('error', error));
    } else {
      toast.warning('Check invalid field values', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  return (
    <>
      <Header
        contactRef={contactRef}
      />
      <HeroInner
        title="Join Processor Network"
        subtitle="Provide access to credit to your network of farmers"
        herotype="hero2"
      />
      <section className="section_light career_openings">
        <div className="container">
          <form className='izimonie_form'>
            <div className='fieldbox'>
              <label>Firstname
                <div className={`label_icon ${!validText(formDetails.firstname) ? 'important' : 'check'}`}></div>
              </label>
              <input type="text"
                name="firstname"
                value={typeof formDetails.firstname === "undefined" ? '' : formDetails.firstname}
                onChange={(e) => updateformDetails('firstname', e.target.value)} />
            </div>
            <div className='fieldbox'>
              <label>Lastname
                <div className={`label_icon ${!validText(formDetails.lastname) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="text"
                name="lastname"
                value={typeof formDetails.lastname === "undefined" ? '' : formDetails.lastname}
                onChange={(e) => updateformDetails('lastname', e.target.value)} />
            </div>
            <div className='fieldbox'>
              <label>Email
                <div className={`label_icon ${!validEmail(formDetails.email) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="email"
                value={typeof formDetails.email === "undefined" ? '' : formDetails.email}
                onChange={(e) => updateformDetails('email', e.target.value)} />
            </div>
            <div className='fieldbox'>
              <label>Phone Number
                <div className={`label_icon ${!validPhone(formDetails.phoneNumber) ? 'important' : 'check'}`}></div>
              </label>
              <PhoneInput
                country={'ng'}
                value={typeof formDetails.phoneNumber === "undefined" ? '' : formDetails.phoneNumber}
                placeholder="+234 1234567891"
                onChange={phone => updateformDetails('phoneNumber', phone)}
              />
            </div>
            <div className='fieldbox'>
              <label>Business Name
                <div className={`label_icon ${!validText(formDetails.businessName) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="text"
                name="businessName"
                value={typeof formDetails.businessName === "undefined" ? '' : formDetails.businessName}
                onChange={(e) => updateformDetails('businessName', e.target.value)} />
            </div>
            <div className='fieldbox'>
              <label>Business Address
                <div className={`label_icon ${!validText(formDetails.businessAddress) ? 'important' : 'check'}`}></div>
              </label>
              <textarea
                name="businessAddress"
                value={typeof formDetails.businessAddress === "undefined" ? '' : formDetails.businessAddress}
                onChange={(e) => updateformDetails('businessAddress', e.target.value)}
              ></textarea>
            </div>
            <div className='fieldbox'>
              <button className={`btn ${allValid() ? 'btn-solid' : 'btn_dumb'}`} onClick={(e) => {
                e.preventDefault()
                submitForm()
              }}>Submit</button>
            </div>
          </form>
        </div>
      </section>
      <div ref={contactRef} >
        <Footer />
      </div>
    </>
  )
}
