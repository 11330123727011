import React, { useState, useRef } from 'react'


export default function MainProvider(props) {
    const contactRef = useRef()
    const approachRef = useRef()
    const headerRef = useRef()
    const [toggleNav, setToggleNav] = useState(false)


    const validText = (value) => {
        if (value === null || typeof value === 'undefined') {
            return false
        } else if (value.length > 1) {
            return true
        } else {
            return false
        }
    }

    const validPhone = (value) => {
        if (value === null || typeof value === 'undefined') {
            return false
        } else if (value.length > 12) {
            return true
        } else {
            return false
        }
    }

    const validEmail = (value) => {
        if (value === null || typeof value === 'undefined') {
            return false
        } else if (/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(value)) {
            return true
        } else {
            return false
        }
    }


    const [openings, setOpenings] = useState([
        {
            id: 0,
            title: "Engineering & Technology",
            description: "Develop the products and tools for the future of agtech in Africa."
        },
        {
            id: 2,
            title: "Sales, Service & Support",
            description: "Equip agtech players with the right tools to help them grow."
        },
        {
            id: 3,
            title: "Design",
            description: "Create beautiful and intuitive experiences."
        },
        {
            id: 4,
            title: "Marketing & Communications",
            description: "Connect players to the magic we are creating."
        },
        {
            id: 5,
            title: "Business Strategy",
            description: "Deliver the analysis and insights we use to innovate."
        },
        {
            id: 6,
            title: "Finance",
            description: "Design and manage solutions to support our business."
        },
        {
            id: 7,
            title: "Legal",
            description: "Shape the future of agrotech policy and law."
        },
        {
            id: 8,
            title: "People",
            description: "Find, grow, and support the people who make Izifin great."
        }
    ])

    return (
        <mainFunctions.Provider
            value={{
                openings,
                setOpenings,
                toggleNav,
                setToggleNav,
                validText,
                validEmail,
                validPhone,
                contactRef,
                approachRef,
                headerRef
            }}
        >
            {props.children}
        </mainFunctions.Provider>
    )
}

export const mainFunctions = React.createContext()
