import React from 'react'

function TabButtons(props) {
  
  return (
    <div className="toggle_box">
          {props.pages.map((page, index)=>{
            return(
                <div className={`toggle_item ${props.activePage === index ? 'active' : ''}`}
                onClick={()=>{
                    props.setActivePage(index)
                }}
                key={index}
                >
                    <div className="index">0{index + 1}</div>
                    <div className="text">{page.title}</div>
                </div>
            )
          })}
      </div>
  )
}

export default TabButtons