import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { mainFunctions } from "../providers/MainProvider";
// import 'animate.css';

function Header(props) {
    const navigate = useNavigate()
    const {
        toggleNav,
        setToggleNav,
        headerRef
    } = useContext(mainFunctions)

    return (
        <section className="header" ref={headerRef}>
            <div className="container">
                <Link to="/">
                    <div className="logo"></div>
                </Link>
                <div className={`nav animated ${toggleNav ? 'show_on_mobile bounceIn' : ''}`}>
                    <div className="nav_item">
                        <Link to="/">Home</Link>
                    </div>
                    <div className="nav_item">
                        <Link to="/approach">Our Approach</Link>
                    </div>
                    <div className="nav_item"
                        onClick={() => navigate("/contactus")}
                    >
                        Contact
                    </div>
                    {/* <div className="nav_item">
                <Link to="/careers">
                    Career
                </Link>
                </div> */}
                </div>
                <div className="anchor"
                    onClick={() => setToggleNav(!toggleNav)}
                ></div>
                {/* <div className="btn top_btn show_large_only">
                Login
            </div> */}
            </div>
        </section>
    )
}

export default Header