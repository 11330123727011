import React from 'react'

export default function ImageSliders() {
  return (
    <div className="image_sliders">
        <div className="image image_1"></div>
        <div className="image image_2"></div>
        <div className="image image_3"></div>
        <div className="image image_4"></div>
        <div className="image image_5"></div>
    </div>
  )
}
