import React from 'react'

function Whoarewe() {
  return (
    <div className="whoarewe_section">
        <div className="section_title">
            Who are we
        </div>
        <div className="section_subtitle">
            What is IziMonie?
        </div>
        <div className="section_text">
        IziMonie was born out of the desire to lend to the most underserved sectors in Subsaharan Africa. 
        By leveraging an array of A.I. & Machine Learning digital products, IziMonie offers affordable and 
        flexible By-Now-Pay-At-Harvest AgriFin services to farmers, whilst also providing access to our 
        Agriculture Lending Platform enabling other lenders to benefit from our Agriculture Decisioning 
        Technology, improving their lending processes and profitability.
        </div>
    </div>
  )
}

export default Whoarewe