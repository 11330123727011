import React from 'react'

export default function CareerDetails() {
  return (
    <section className="section_light section_white contactus">
        <div className="container row">
            <div className="section_side col-md-6">
                <div className="title">Description</div>
                <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
            
                <div className="title">Deliverables</div>
                <div className="text">
                    <ul>
                        <li>Original Certificate of registration of the business name</li>
                        <li>Certified true copies of the Application for the Registration of the business name</li>
                        <li>Original certificate of incorporation</li>
                    </ul>
                </div>
                <div className="title">Qualifications</div>
                <div className="text">
                    <ul>
                        <li>Original Certificate of registration of the business name</li>
                        <li>Certified true copies of the Application for the Registration of the business name</li>
                        <li>Original certificate of incorporation</li>
                    </ul>
                </div>
                <div className="title">Salary</div>
                <div className="text">
                    450k 
                </div>
            </div>
            <div className="section_pop_form col-md-6">
                <div className="container">
                    <div className="title">Job Application</div>
                    <div className="fieldset">
                        <label>Full Name</label>
                        <input className="input" />
                    </div>
                    <div className="fieldset">
                        <label>Email Address</label>
                        <input className="input" />
                    </div>
                    <div className="fieldset">
                        <label>Phone Number</label>
                        <input className="input" />
                    </div>
                    <div className="fieldset">
                        <label>Resume</label>
                        <div className="input">
                            <div className="filename">Pdf file only</div>
                            <div className="btn btn_bland">UPLOAD FILE</div>
                        </div>
                    </div>
                    <div className="fieldset">
                        <label>Cover Letter</label>
                        <div className="input">
                            <div className="filename">Pdf file only</div>
                            <div className="btn btn_bland">UPLOAD FILE</div>
                        </div>
                    </div>
                    <center>
                        <div className="btn btn_solid">
                            Send Application
                        </div>
                    </center>
                </div>
            </div>
        </div>
    </section>
  )
}
