import React, { useContext } from 'react';
import Header from '../components/Header'
import Approach from '../components/Approach'
import HeroInner from '../components/HeroInner'
import Footer from '../components/Footer'
import { mainFunctions } from "../providers/MainProvider";

export default function OurApproach() {
  const {
    approachRef
  } = useContext(mainFunctions)
  return (
    <>
      <Header ref={approachRef} />
      <HeroInner
        title="Our Approach"
        subtitle="With rising prices, good quality seeds, fertilisers, and farming equipment are becoming prohibitively expensive to purchase upfront. This is especially difficult for farmers who grow crops with longer harvest cycles."
        herotype="hero2"
      />
      <Approach />
      <div>
        <Footer />
      </div>
    </>
  )
}
