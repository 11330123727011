import React, { createRef } from 'react';
import Header from '../components/Header'
import CareerListing from '../components/CareerListing'
import HeroInner from '../components/HeroInner'
import Footer from '../components/Footer'

export default function CareerListingPage() {
  const contactRef = createRef()
  return (
    <>
      <Header 
        contactRef={contactRef}
      />
      <HeroInner 
      title="Join the Team"
      subtitle="Let's revolutionize Agro-tech in Africa."
      herotype="hero2"
      />
      <CareerListing />
      <div ref={contactRef} >
        <Footer />
      </div>
    </>
  )
}
