import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { mainFunctions } from "../providers/MainProvider";
function Footer() {
    const navigate = useNavigate()
    const {
        validEmail,
        approachRef,
        contactRef,
        headerRef
    } = useContext(mainFunctions)
    const [formDetails, setformDetails] = useState({
        title: 'newsletter',
        source: 'izimonie',
        email: '',

    })

    const updateformDetails = (name, value) => {
        setformDetails({ ...formDetails, [name]: value })
    }

    const submitForm = () => {
        if (typeof formDetails.email !== "undefined"
            && validEmail(formDetails.email)
        ) {
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(formDetails),
                redirect: 'follow'
            };

            fetch("https://pqa45jgunk.execute-api.us-east-2.amazonaws.com/default/FormEmailSenderV2", requestOptions)
                .then(response => response.json())
                .then(result => {
                    toast.success('Email added to newletter', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setformDetails({
                        title: "izimonie_newsletter"
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            toast.warning('Enter a valid Email Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    return (
        <section className="footer">
            <div className="container">
                <div className="footer_top">
                    <div className="footer_intro">
                        <div className="title">Get Started Today</div>
                        <div className="subtitle">Join our network of Merchant Sellers or Food Processors today and add digital financing into your farming processes</div>
                    </div>
                    <div className="footer_buttons">
                        <div className="btn btn_solid" onClick={() => navigate("/merchant")}>Join Merchant Network</div>
                        <div className="btn btn-light" onClick={() => navigate("/processor")}>Join Processor Network</div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="">
                        <div className="logo" onClick={() => {
                            navigate("/")
                        }}></div>
                    </div>
                    <div className="footer_bottom_side">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="title">
                                    Navigation
                                </div>
                                <div className="footer_list">
                                    <div className="footer_item" onClick={
                                        async () => {
                                            await navigate("/approach")
                                            await headerRef.current.scrollIntoView()
                                        }}>Our Approach</div>
                                    <div className="footer_item" onClick={
                                        async () => {
                                            await navigate("/contactus")
                                            await headerRef.current.scrollIntoView()
                                        }}>Contact Us</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                {/* <div className="title">
                                    Follow us
                                </div>
                                <div className="social_media_icons">
                                    <div className="icon linkedIn"></div>
                                    <div className="icon facebook"></div>
                                    <div className="icon instagram"></div>
                                    <div className="icon twitter"></div>
                                    <div className="icon youtube"></div>
                                </div> */}
                            </div>
                            <div className="col-md-4">
                                <div className="title">Newsletter</div>
                                <div className="newsletter_text">Sign up to our newsletter and keep informed</div>
                                <div className="fieldset">
                                    <input placeholder="Email Address"
                                        value={formDetails.email ? '' : formDetails.email}
                                        onChange={(e) => updateformDetails('email', e.target.value)} />
                                    <div className="btn" onClick={() => submitForm()}>
                                        Send
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footnotes">
                    <div className="copyright">
                        © Izifin Technologies Limited, 2023
                    </div>
                    {/* <div className="others">
                        <div style={{ marginRight: "20px" }}>Terms of Use</div>
                        <div>Privacy Policy</div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Footer
