import React from 'react'

function Features() {
  return (
    <section className="section_dark2 section_curved">
        <div className="container">
        <div className="section_title">Farming in the digital age</div>
        <div className="section_subtitle">Innovative Technologies</div>
        <div className="section_text">IziMonie leverages data from multiple sources and applies advanced Machine Learning techniques to make credit risk decisions and provide actionable insights to farmers</div>
    
    
        <div className="features_list">
            
            <div className="feature_item no_top_margin">
                <div className="icon image_processing"></div>
                <div className="title">Image Processing</div>
                <div className="text">
                IziMonie uses satellite and drone imagery to see climate infrastructure, monitors the environmental conditions, and detects the responses of upcoming disasters. 
                <br/>
                Image Processing is also used for remote sensing and to collect coherent information about the earth surface
                </div>
            </div>

            <div className="feature_item no_top_margin">
                <div className="icon anomaly_detection"></div>
                <div className="title">Anomaly Detection</div>
                <div className="text">
                    Rare soil and climate conditions can be identified through observations, leveraging our expertise in novelty detection. 
                    This can point out conditions that deviate significantly from the majority and does not conform with the normal expected states of these elements
                </div>
            </div>

            <div className="feature_item">
                <div className="icon decisioning_analytics"></div>
                <div className="title">Decisioning Analytics</div>
                <div className="text">
                Aside from consolidating Data for Credit Decisioning purposes, IziMonie blends Data, Artificial Intelligence and Predictive Analytics to enable lenders to make data-driven lending decisions for farmer loans, whilst also providing actionable insights for portfolio management, improving lending performance and profitability
                </div>
            </div>

            <div className="feature_item">
                <div className="icon insights"></div>
                <div className="title">Insights, Reporting & Alerts</div>
                <div className="text">
                    IziMonie reports real time insights to users on multiple channels including smart phone alerts, SMS notification, and emails
                </div>
            </div>


        </div>
    </div>
  </section>
  )
}

export default Features