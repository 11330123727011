import React from 'react';

import './assets/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Routing from './Routing';
import MainProvider from './providers/MainProvider';

function App() {
  return (
    <div className="main">
      <ToastContainer />
      <MainProvider>
        <Routing />
      </MainProvider>
    </div>
  );
}

export default App;
