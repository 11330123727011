import React,{useContext, useEffect} from 'react';
import { Routes, Route, useLocation} from 'react-router-dom'
import {mainFunctions} from "./providers/MainProvider";

import CareerDetailsPage from './pages/CareerDetailsPage';
import CareerListingPage from './pages/CareerListingPage';
import Landing from './pages/Landing';
import OurApproach from './pages/OurApproach';
import MerchantPage from './pages/MerchantPage';
import ProcessorNetwork from './pages/ProcessorNetwork';
import ContactUs from './pages/ContactUs';

export default function Routing() {
   
    const {
        setToggleNav
    } = useContext(mainFunctions)

    const location = useLocation()
    useEffect(() => {
        setToggleNav(false);
        // eslint-disable-next-line
    }, [location])
    return (
        <>
            <Routes>
                <Route exact path="/" element={<Landing />} />
                <Route exact path="/home" element={<Landing />} />
                <Route exact path="/approach" element={<OurApproach />} />
                <Route exact path="/merchant" element={<MerchantPage />} />
                <Route exact path="/contactus" element={<ContactUs />} /> 
                <Route exact path="/processor" element={<ProcessorNetwork />} />
                <Route exact path="/careers" element={<CareerListingPage/>} />
                <Route exact path="/careers/:id" element={<CareerDetailsPage />} />
            </Routes>
        </>
    );
}
