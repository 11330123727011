import React, {useState} from 'react'
import Showcase from './Showcase'
import TabButtons from './TabButtons'
export default function Approach() {
    const [activePage, setActivePage] = useState(0)
    const tabPages = {
        activePage,
        setActivePage,
        pages:[
          {
            id: 0,
            icon:'hide',
            imageClass:"foodsecurity",
            title: "Merchants",
            text: "Merchants are wholesalers and suppliers of seeds, soil, fertiliser, and machinery, needed at various points of the farming life cycle. IziMonie provides direct financing to Merchants for products needed by farmers throughout the farming cycle. Merchants are also provided with a digital platform that can be accessed via a laptop, smartphone, or tablet, allowing them to onboard farmers and determine their credit capacity at the point of sale. This process is hyper convenient for farmers whilst Merchants also get the benefits of more sales and cash flow. Credit Decisions are made instantly, with the Merchant being paid immediately, so the farmers can take their acquired goods home the same day.",
            btnClass: 'btn_bland',
            btnText: 'Join our network of processors',
            btnAction: ()=>{}
        },
          {
            id: 1,
            icon:'hide',
            imageClass:"foodsecurity",
            title: "Processors",
            text:"Processors are companies or Local Buying Agencies that purchase goods from multiple farmers. Farmers that are well financed generate better yield, better quality products, and improve a Processor's outcome towards their quota. This also prevents Processors from paying advances to farmers, improving cashflow. Equipped with our Platform, a Processor can make payments to farmers, track historical performances, and access insights on forecasted risks and opportunities.",
            btnClass: 'btn_bland',
            btnText: 'Join our network of processors',
            btnAction: ()=>{}
        }
        ]
        }

    return (

    <section className="section_dark section_curved">
                <div className="container">
                <div className="section_subtitle">Who do we work with?</div>
                {/* <div className="section_text">
                    The benefits of Artificial Intelligence and Analytics applied to Finance for Agriculture goes beyond generating affordable AgriFin services but also benefitting the environment and Ag-ecosystem
                </div> */}

                <TabButtons 
                {...tabPages}
                />

                <Showcase
                {...tabPages}
                />
                

               
            </div>
    </section>
  )
}
