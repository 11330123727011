import React, { useState, useContext } from 'react';
import Header from '../components/Header'
// import CareerListing from '../components/CareerListing'
import HeroInner from '../components/HeroInner'
import Footer from '../components/Footer'
import { toast } from 'react-toastify';
import { mainFunctions } from "../providers/MainProvider";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function ContactUs() {

  const {
    validText,
    validPhone,
    validEmail,
    contactRef
  } = useContext(mainFunctions)


  const [formDetails, setformDetails] = useState({
    title: "contact_us",
    source: "izimonie"
  })

  const updateContact = (name, value) => {
    setformDetails({ ...formDetails, [name]: value })
  }

  const allValid = () => {
    return validEmail(formDetails.email) &&
      validPhone(formDetails.phoneNumber) &&
      validText(formDetails.firstname) &&
      validText(formDetails.lastname) &&
      validText(formDetails.message)
  }
  const submitForm = () => {
    if (
      allValid()
    ) {

      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(formDetails),
        redirect: 'follow'
      };

      fetch("https://pqa45jgunk.execute-api.us-east-2.amazonaws.com/default/FormEmailSenderV2", requestOptions)
        .then(response => response.json())
        .then(result => {
          toast.success('Contact us form submitted', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setformDetails({
            title: "Contact Us"
          })
        })
        .catch(error => console.log('error', error));
    } else {
      toast.warning('Check invalid field values', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  return (
    <>
      <Header
        ref={contactRef}
      />
      <HeroInner
        title="Contact Us"
        subtitle=""
        herotype="hero2"
      />
      <section className="section_light career_openings">
        <div className="container">
          <form className='izimonie_form'>
            <div className='fieldbox'>
              <label>Firstname
                <div className={`label_icon ${!validText(formDetails.firstname) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="text"
                name="firstname"
                value={typeof formDetails.firstname === "undefined" ? '' : formDetails.firstname}
                onChange={(e) => updateContact('firstname', e.target.value)} />
            </div>
            <div className='fieldbox'>
              <label>Lastname
                <div className={`label_icon ${!validText(formDetails.lastname) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="text"
                name="lastname"
                value={typeof formDetails.lastname === "undefined" ? '' : formDetails.lastname}
                onChange={(e) => updateContact('lastname', e.target.value)}
              />
            </div>
            <div className='fieldbox'>
              <label>Email
                <div className={`label_icon ${!validEmail(formDetails.email) ? 'important' : 'check'}`}></div>
              </label>
              <input
                type="email"
                name="email"
                value={typeof formDetails.email === "undefined" ? '' : formDetails.email}
                onChange={(e) => updateContact('email', e.target.value)}
              />
            </div>
            <div className='fieldbox'>
              <label>Phone Number
                <div className={`label_icon ${!validText(formDetails.phoneNumber) ? 'important' : 'check'}`}></div>
              </label>
              <PhoneInput
                country={'ng'}
                value={formDetails.phoneNumber}
                placeholder="+234 1234567891"
                onChange={phone => updateContact('phoneNumber', phone)}
              />
            </div>
            <div className='fieldbox'>
              <label>Message
                <div className={`label_icon ${!validText(formDetails.message) ? 'important' : 'check'}`}></div>
              </label>
              <textarea
                name="message"
                value={typeof formDetails.message === "undefined" ? '' : formDetails.message}
                onChange={(e) => updateContact('message', e.target.value)}
              ></textarea>
            </div>
            <div className='fieldbox'>
              <button
                className={`btn ${allValid() ? 'btn_solid' : 'btn_dumb'}`}
                onClick={(e) => {
                  e.preventDefault()
                  submitForm()
                }}>Submit</button>
            </div>
          </form>
        </div>
      </section>
      <div ref={contactRef} >
        <Footer />
      </div>
    </>
  )
}
